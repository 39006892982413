import React, { useContext, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import "./themebtn.scss";
import ThemeContext from "./contexts/themecontexts";
// img
// import { Image } from "react-bootstrap";
// import SunIcon from "../img/sun.png";
// import MoonIcon from "../img/moon.png";

import { BsMoonStarsFill, BsSunFill } from "react-icons/bs";

export default function ThemeSetter() {
  const { theme, setTheme } = useContext(ThemeContext);

  const [checked, setChecked] = useState(true);

  checked ? setTheme("light") : setTheme("dark");

  return (
    <>
      <div className="theme-btn">
        <div className="checkbox-wrapper-54">
          <OverlayTrigger
            key="top"
            placement="top"
            delay={{ show: 0, hide: 0 }}
            overlay={
              <Tooltip id="light-dark-tool">
                {checked ? "Enable Dark Mode" : "Enable Light Mode"}
              </Tooltip>
            }
          >
            <label className="switch">
              <input
                type="checkbox"
                checked={checked}
                onChange={() => setChecked(toggle)}
              />
              <span className="slider">
                {checked ? <BsMoonStarsFill /> : <BsSunFill />}
              </span>
              {/* <span className="slider">
                {checked ? (
                  <>
                    <Image src={MoonIcon} fluid />
                  </>
                ) : (
                  <>
                    <Image src={SunIcon} fluid />
                  </>
                )}
              </span> */}
            </label>
          </OverlayTrigger>
        </div>
        {/* Checking Reference */}
        {/* <p>This box is {checked ? "a" : "b"}</p> */}
        <p
          style={{
            display: " none",
          }}
        >
          This box is {theme}
        </p>
      </div>
    </>
  );
}
function toggle(value) {
  return !value;
}
