// src/reducers/userReducer.js
import {
    GET_FOOTER_SUCCESS,
    GET_FOOTER_FAILURE,
  } from "../../actions/types";
  
  const initialState = {
    isLoading: false,
    response: null,
    error: null,
  };
  
  export const footerReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_FOOTER_SUCCESS:
        return {
          ...state,
          isLoading: false,
          response: action.payload,
          error: null,
        };
      case GET_FOOTER_FAILURE:
        return {
          ...state,
          isLoading: false,
          response: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  