// CSS
import "./App.css";
import ThemeSetter from "./common/theme/components/themeshetter";
import ThemeProvider from "./common/theme/components/contexts/themeprovider";
import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useRef,
} from "react";
import {
  // PaymentRequestButtonElement,
  useStripe,
  // useElements,
} from "@stripe/react-stripe-js";

import { connect } from "react-redux";
import { setStripe } from "./actions/stripe/setStripeActions";

export const OrderFlowProvider = createContext({});
export const OrderFlow = () => useContext(OrderFlowProvider);

const RestaurantMain = React.lazy(() => import("./pages/restuarant-page/main"));
const TakeawayMain = React.lazy(() => import("./pages/takeaway-page/main"));
function App({ apiResponse, setStripe }) {
  const stripe = useStripe();
  const mobileref = useRef();
  const [getLogo, setLogo] = useState(null);

  useEffect(() => {
    if (stripe) {
      setStripe(stripe); // Dispatch the Stripe object to Redux
    }
  }, [stripe, setStripe]);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    const appleIcon = document.getElementById("appleIcon");
    const chromeIcon192 = document.getElementById("chromeIcon192");
    const chromeIcon512 = document.getElementById("chromeIcon512");
    console.log("getLogo:", getLogo);
    if (getLogo) {
      if (favicon) favicon.href = getLogo;
      if (appleIcon) appleIcon.href = getLogo;
      if (chromeIcon192) chromeIcon192.href = getLogo;
      if (chromeIcon512) chromeIcon512.href = getLogo;
    }
  }, [getLogo]);

  const [loginPopupShow, setLoginPopupShow] = useState(false);
  const [getOrderTime, setOrderTime] = useState(
    localStorage.getItem("orderTime")
  );
  const [getOrderMode, setOrderMode] = useState(
    localStorage.getItem("orderMode")
      ? localStorage.getItem("orderMode")
      : "Delivery"
  );
  const [getCollectionTab, setCollectionTab] = useState(true);
  const [getDeliveryTab, setDeliveryTab] = useState(true);
  const [moveToCheck, setMoveToCheck] = useState(
    localStorage.getItem("moveToCheck")
      ? localStorage.getItem("moveToCheck")
      : false
  );
  const [stuartStatus, setStuartStatus] = useState(false);
  const [restaurant, setRestaurant] = useState(null);
  const [footerLoading, setFooterLoading] = useState(false);
  //postcode change
  const [reslimit, setReslimit] = useState(1);
  const [restaurantData, setRestaurantData] = useState([]);
  const [hasFetchedData, updateHasFetchedData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [cartQuantity, setCartQuantity] = useState(null);

  const [scrolltableBooking,setScolltableBooking]=useState(null);

  const bookNavRef = useRef(null);

  //activated
  const [activatedPopup, setActivatedPopup] = useState(false);

  useEffect(() => {
    setFooterLoading(true);
    setCartQuantity(localStorage.getItem("cartQty"));
  }, []);
  useEffect(() => {
    // console.log(footerLoading);
  }, [footerLoading]);
  const handleclick = () => {
    setFooterLoading(false);
  };

  const TopQty = () => {
    const cartQty = localStorage.getItem("cartQty");
    setCartQuantity(cartQty);
  };

  const handleActivated = () => {
    setActivatedPopup(true);
  };

  const [businessTitle, setBusinessTitle] = useState("");

  return (
    <>
      <ThemeProvider>
        <ThemeSetter />
        <OrderFlowProvider.Provider
          value={{
            loginPopupShow,
            setLoginPopupShow,
            getOrderTime,
            setOrderTime,
            getOrderMode,
            setOrderMode,
            getCollectionTab,
            setCollectionTab,
            getDeliveryTab,
            setDeliveryTab,
            moveToCheck,
            setMoveToCheck,
            stuartStatus,
            setStuartStatus,
            restaurant,
            setRestaurant,
            footerLoading,
            setFooterLoading,
            handleclick,
            reslimit,
            setReslimit,
            restaurantData,
            setRestaurantData,
            hasFetchedData,
            updateHasFetchedData,
            mobileref,
            searchQuery,
            setSearchQuery,
            businessTitle,
            setBusinessTitle,
            TopQty,
            activatedPopup,
            setActivatedPopup,
            handleActivated,
            setLogo,
            bookNavRef,
            scrolltableBooking,
            setScolltableBooking
          }}
        >
          {apiResponse === 1 ? (
            <React.Suspense>
              <RestaurantMain />
            </React.Suspense>
          ) : (
            <React.Suspense>
              <TakeawayMain />
            </React.Suspense>
          )}
        </OrderFlowProvider.Provider>
      </ThemeProvider>
    </>
  );
}

const mapDispatchToProps = {
  setStripe,
};

export default connect(null, mapDispatchToProps)(App);
