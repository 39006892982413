import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import store from "./store";
import { createRoot } from "react-dom/client";

const stripePromise = loadStripe("pk_live_DIodJvzwckwG0omwLcjh3E2k00A880PBDB");
// const stripePromise = loadStripe("pk_test_9CnYE16SY0ju0M4GcnOBHzku00gC8VQDPF");
const fetchData = async () => {
  try {
    const response = await fetch(
      "https://api-landing.fusionkitchen.co.uk/businessType",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          domain: window.location.hostname,
        }),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const renderApp = async () => {
  try {
    const apiResponse = await fetchData();
    createRoot(document.getElementById("root")).render(
      <Elements stripe={stripePromise}>
        <React.StrictMode>
          <Provider store={store}>
            <App apiResponse={apiResponse?.data?.businessType} />
          </Provider>
        </React.StrictMode>
      </Elements>
    );
  } catch (error) {
    console.error("Error rendering app:", error);
  }
};

renderApp();

reportWebVitals();
